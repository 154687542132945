.ScoreBoxWrapper {
	.score-box {
		background: white;
		display: inline-block;
		line-height: 30px;
		font-size: 16px;
		font-weight: 500;
		color: $brand-black;
		text-align: center;
		height: 30px;
		min-width: 40px;
		box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.1);
		border-top: 2px solid $brand-dark-grey;

		&.score-box--win {
			border-top: 2px solid $brand-success;
		}

		&.score-box--lose {
			border-top: 2px solid $brand-danger;
		}

		input {
			display: inline-block;
			width: 40px;
			height: 100%;
			border: none;
			text-align: center;
		}

		input[type='number']::-webkit-inner-spin-button,
		input[type='number']::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}

	i {
		font-style: normal;
		position: relative;
		top: -3px;
		font-weight: bold;
	}
}
