.irs-line {
	height: 3px;
	background: $brand-light-grey;

	.irs-line-mid,
	.irs-line-left,
	.irs-line-right {
		background: none;
	}
}

.irs-bar {
	height: 3px;
	background: #4a4a4a;
	box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
}

.irs-slider {
	width: 11px;
	height: 11px;
	background: $brand-accent;
	border-radius: 100%;
	top: 20px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

	&:hover {
		cursor: pointer;
	}
}

.irs-min,
.irs-max {
	top: 34px;
	background: transparent;
	color: #a3a3a3;
	font-size: 9px;
}
.irs-from,
.irs-to {
	top: 34px;
	font-size: 12px;
	color: #4a4a4a;
	background: none;
}
