.text-uppercase {
	text-transform: uppercase;
}

.pull-right {
	float: right;
}

.pull-left {
	float: left;
}

.text-center {
	text-align: center;
}

.no-margin {
	margin-left: 0;
	margin-right: 0;
	margin-top: 0;
	margin-bottom: 0;
}

.no-padding {
	padding-left: 0;
	padding-right: 0;
	padding-top: 0;
	padding-bottom: 0;
}

.no-mg-top {
	margin-top: 0 !important;
}

.mg-bottom-20 {
	margin-bottom: 20px;
}

.mg-top-20 {
	margin-top: 20px;
}

.padding-15 {
	padding: 15px;
}

.padding-20 {
	padding: 20px;
}

.padding-top-20 {
	padding-top: 20px;
}

.padding-right-20 {
	padding-right: 20px;
}

.padding-right-15 {
	padding-right: 15px;
}

.padding-left-15 {
	padding-right: 15px;
}

.padding-bottom-20 {
	padding-bottom: 20px;
}

.text-show-newline {
	white-space: pre-wrap;
}

.truncate-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.scrollable {
	overflow: auto;
}
