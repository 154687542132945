.inner-page__content > .col-md-12 {
	height: 100%;
}

.inner_page__form {
	height: 100%;

	.inner-page__content {
		height: calc(100% - 85px);
	}

	.WizardForm__navigation__slide-info {
		margin-top: 15px;
	}

	.bottom-nav-bar__inner {
		margin-left: 180px;

		.bottom-nav-bar__inner__content {
			max-width: 550px;
			margin: 0 auto;
			padding: 8px 0;

			button {
				height: 35px;
				min-width: 77px;

				i {
					font-size: 18px;
					position: relative;
					top: 2px;
					right: -5px;
				}

				&.button-back {
					i {
						left: -5px;
					}
				}

				&.button-dark {
					width: auto;
					background: #4a4a4a;
					padding: 5px 15px;
				}
			}
		}
	}
}

.WizardForm {
	display: flex;
	height: calc(100% - 120px);
	overflow: auto;

	&.WizardForm__has-bottom-nav {
		height: calc(100% - 50px);
	}

	&.WizardForm__left-fix {
		margin-left: -10px;
	}

	.WizardForm__navigation {
		padding: 10px;
		text-align: center;
		min-width: 0;
		width: 180px;
		height: 100%;
		background: lighten($brand-light-grey, 5%);
	}

	@media screen and (max-width: 1060px) {
		.WizardForm__navigation {
			display: none;
		}
	}

	.WizardForm__navigation__header {
		i {
			font-size: 26px;
			color: $brand-accent;
			display: block;
			margin-bottom: 20px;
		}

		h3 {
			color: $brand-accent;
			font-size: 16px;
			font-weight: bold;
			font-family: $font-brand;
			line-height: 1.62;
			margin-top: 5px;
			margin-bottom: 35px;
		}
	}

	.WizardForm__navigation__bullets {
		ul {
			justify-content: space-around;
			display: flex;

			button {
				font-size: 14px;
				font-family: $font-brand;
				font-weight: bold;
				color: $brand-dark;

				&.button-assertive {
					color: white;
				}
			}
		}
	}

	.WizardForm__navigation__slide-info {
		margin-top: 55px;

		i {
			font-size: 40px;
			color: $brand-dark;
			display: block;
		}

		h5 {
			color: $brand-dark;
			font-size: 12px;
			margin-top: 20px;
			font-weight: bold;
			margin-bottom: 10px;
		}

		p {
			font-size: 10px;
			line-height: 1.95;
		}

		&.WizardForm__navigation__slide-info--accent {
			i {
				color: $brand-accent;
			}

			h5 {
				color: $brand-accent;
			}
		}
	}

	.WizardForm__content {
		flex-grow: 1;
		height: 100%;
		overflow: auto;

		.WizardForm__content__inner {
			max-width: 550px;
			margin: 0 auto;

			&.WizardForm__content__inner--full {
				max-width: 100%;
				padding: 15px;
			}
		}
	}
}
