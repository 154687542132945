.rc-toggle-wrapper {
	display: inline-block;
}
.rc-toggle {
	display: none;

	// add default box-sizing for this scope
	&,
	&:after,
	&:before,
	& *,
	& *:after,
	& *:before,
	& + .tgl-btn {
		box-sizing: border-box;
		&::selection {
			background: none;
		}
	}

	+ .rc-toggle-btn {
		outline: 0;
		display: block;
		width: 50px;
		height: 25px;
		position: relative;
		cursor: pointer;
		user-select: none;
		&:after,
		&:before {
			position: relative;
			display: block;
			content: '';
			width: 50%;
			height: 100%;
		}

		&:after {
			left: 0;
		}

		&:before {
			display: none;
		}
	}

	&:checked + .rc-toggle-btn:after {
		left: 50%;
	}
}

// themes
.rc-toggle-light {
	+ .rc-toggle-btn {
		background: #9b9b9b;
		border-radius: 2em;
		padding: 2px;
		transition: all 0.4s ease;
		&:after {
			border-radius: 50%;
			background: #fff;
			transition: all 0.2s ease;
		}
	}

	&:checked + .rc-toggle-btn {
		background: #ffa31d;
	}
}
