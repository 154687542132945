.tabs__navigation {
	a {
		color: $brand-grey;
		text-decoration: none;
		font-weight: bold;
		transition: color 0.3s;

		&:hover {
			color: lighten($brand-grey, 10%);
		}
	}

	li {
		margin-right: 50px;

		&.active {
			position: relative;

			a {
				color: $brand-dark;
			}

			&::after {
				content: '';
				display: inline-block;
				height: 3px;
				width: 100%;
				background: $brand-accent;
				position: absolute;
				bottom: -7px;
				left: 0;
			}
		}
	}
}
