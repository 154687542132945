/****************************************
*
*        recCenter Global styles
*
*****************************************/
@import 'utils/reset';

// Variables
@import 'variables/index';

// Vendors
@import 'vendors/index';

// Utilities
@import 'utils/helpers';

// re-usable Components
@import 'components/index';

// Base Styles
// Typography
@import 'base/index';
