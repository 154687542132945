.reachout-widget-wrapper {
	.sport-float-icon {
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		bottom: -17px;
		-webkit-transform: translate3d(0, 0, 0);

		span {
			font-size: 45px;
		}
	}
	.reachout-widget-wrapper__top {
		.reachout-widget-wrapper__image {
			position: relative;
			height: 166px;
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			width: 276px;
			margin: 0 auto;
			text-align: center;
		}
	}

	::ng-deep .avatar,
	::ng-deep .participant-logo {
		height: 115px !important;
		width: 115px !important;
		position: relative;
		top: 21px !important;
	}

	.reachout-widget-wrapper__content {
		text-align: center;

		h5 {
			text-align: center;
			color: $brand-black;
			font-size: 24px;
			font-family: $font-brand;
			font-weight: bold;
		}
	}

	.rechout-info-box {
		border-top: 4px solid $brand-accent;
		position: relative;
		z-index: 2;
		background: white;
		height: 59px;
		padding: 10px;
		box-shadow: 0 9px 10px 0 rgba(0, 0, 0, 0.1);
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		width: 124px;
		display: inline-block;
		margin-right: 2px;
		margin-bottom: 20px;

		h6 {
			text-align: left;
			margin: 0;
			font-size: 12px;
			color: $brand-dark-grey;
			font-weight: normal;
			margin-bottom: 3px;
		}

		p {
			text-align: left;
			font-family: 'Brandon';
			font-size: 15px;
			font-weight: bold;
			color: $brand-black;
			text-align: left;
			line-height: 16px;
			margin-bottom: 0;
		}
	}
}
