html,
body {
	font-family: $font-default;
	font-size: $font-size-base;
}

h1,
h2,
h3,
h4,
h5 {
}

p {
}

h1 {
	line-height: 48px;
}

.box-title {
	font-size: 11px;
	font-weight: 600;
	color: $brand-dark;

	&.box--title-icon {
		i {
			position: relative;
			top: 2px;
			font-size: 14px;
			color: $brand-accent;
			display: inline-block;
			margin-right: 5px;
		}
	}

	.button.accent-title-button {
		color: $brand-accent;
		font-weight: 700;
		font-size: 12px;
		float: right;
		transition: color 0.3s;

		&:hover {
			color: lighten($brand-accent, 10%);
		}
	}
}

.sub-title {
	font-size: 14px;
	font-weight: 600;

	&.sub-title--assertive {
		color: $brand-accent;
	}

	&.sub-title--icon {
		i {
			color: $brand-accent;
		}
	}

	.accent-title-button {
		float: right;
		color: $brand-accent;
		font-size: 12px;
	}
}

.bold {
	font-weight: bold;
}

.title-button-group {
	.button {
		position: relative;
		top: -10px;
	}
}
