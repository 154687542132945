.button {
	font-family: $font-brand;
	color: white;
	padding: 5px 10px;
	border-radius: 3px;
	border: none;
	outline: none;
	transition: all 0.2s;

	&.button-assertive {
		background: $brand-accent;

		&:disabled {
			color: rgba(255, 255, 255, 0.5);
		}

		&:not([disabled]):hover {
			background: $brand-accent-light;
			color: white;
		}
	}

	&.button-danger {
		background: $brand-danger;
		color: white;
	}

	&.button-black {
		background: $brand-black;
		color: white;

		&:disabled {
			color: rgba(255, 255, 255, 0.5);
		}

		&:not([disabled]):hover {
			background: lighten($brand-black, 10%);
			color: white;
		}
	}

	&.button-success {
		background: $brand-success;

		&:disabled {
			color: rgba(255, 255, 255, 0.5);
		}

		&:not([disabled]):hover {
			background: lighten($brand-success, 10%);
		}
	}

	&.button-shadow {
		box-shadow: 0 6px 11px 0 rgba(255, 163, 29, 0.34);

		&--grey {
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
		}
	}

	&.button-full {
		padding: 10px;
		width: 100%;
	}

	&.button-loader {
		height: 40px;

		span {
			margin: 0 auto;
			left: 0;
			right: 0;
			top: 15px;
			position: absolute;
		}
	}

	&.button-circle {
		width: 30px;
		height: 30px;
		border-radius: 100%;
	}

	&.button-bold {
		font-weight: bold;
	}

	&.button-empty {
		background: transparent;
		color: $brand-dark-grey;

		&:hover {
			color: $brand-grey;
		}
	}

	&.button-assertive--inverted {
		border: solid 1px $brand-accent;
		color: $brand-accent;

		&:hover {
			color: $brand-accent-light;
		}
	}

	&.button-clear {
		background: transparent;
	}

	&.button-padded {
		padding-left: 30px;
		padding-right: 30px;
	}

	&.button-text {
		background: transparent;
		color: $brand-dark-grey;
		font-weight: bold;
	}
}

.add-card {
	width: 131px;
	height: 130px;
	position: relative;
	background-size: cover;
	background-position: center;
	margin-right: 5px;
	box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.1);
	padding-left: 0;
	margin-bottom: 5px;

	&.add-card--success-bg {
		background-color: $brand-success;
	}

	&.add-card--small {
		height: 72px;
	}

	&:hover {
		cursor: pointer;
	}

	.inner-overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000);
	}

	.add-card__text {
		position: absolute;
		width: 100%;
		font-size: 18px;
		font-weight: 900;
		font-family: $font-brand;
		color: white;
		bottom: 0;
		padding: 12px 8px;
		line-height: 23.5px;
	}
}

.DropdownSelectWrapper {
	width: 100%;

	.DropdownSelect {
		text-align: left;
		padding: 5px 10px;
		height: 35px;
		border-radius: 2px;
		background-color: #ffffff;
		border: solid 1px #d5d5d5;
		width: 100%;
		font-size: 12px;
		color: $brand-black;
		font-weight: 200;

		span {
			float: right;
			font-size: 18px;
			line-height: 2px;
			position: relative;
			left: 3px;
			top: 8px;
			color: #c0c0c0;
		}
	}

	ul {
		li {
			margin: 0 5px;
			padding: 5px 0;
			border-bottom: 1px solid $brand-grey;
			transition: all 0.2s;
			font-size: 10px;
			color: $brand-dark;

			a {
				padding: 0;
				color: $brand-dark-grey;
				transition: all 0.3s;

				&:hover {
					background: transparent;
				}
			}

			i {
				display: inline-block;
				width: 20px;
				text-align: center;
			}

			&:last-child {
				border-bottom: none;
			}

			&:hover {
				cursor: pointer;
				color: $brand-accent;
			}

			&.danger {
				&:hover {
					cursor: pointer;
					color: $brand-danger;
				}
			}
		}
	}

	.dropdown-menu {
		left: 20px;
	}
}
