@import '~@rcenter/core/scss/variables/index';

body {
	margin: 0 !important;
}
.page-header {
	padding-left: 2vw;
	padding-right: 2vw;
}

.slider-area-wrapper {
	display: flex;
	overflow: auto;
	-webkit-overflow-scrolling: touch;

	.item {
		position: relative;
	}
}

.add-activity-venue-btn,
.add-form-btn {
	background: $brand-accent;
	color: white;
	font-size: 12px;
	font-weight: bold;
	padding: 14px;

	i {
		position: relative;
		top: -2px;
		font-size: 8px;
	}

	span {
		font-weight: normal;
	}

	a {
		color: $brand-extra-light-grey;
	}
}

.concurrent-wrapper {
	.concurrent-wrapper--header {
		font-size: 11px;
		color: $brand-black;
	}

	.concurrent-wrapper--input {
		position: relative;
		top: 3px;

		.input {
			max-width: 55px;
		}
	}
}

.popover {
	border-radius: 2px;
	box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px $brand-grey;

	.popover-content {
		padding: 5px;
	}
}

.ReportDownloadBtn {
	background: white;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
	font-weight: bold;
	color: $brand-accent;
	line-height: 33px;
	padding: 0 15px;
	display: inline-block;

	i {
		display: inline-block;
		margin-right: 5px;
	}
}

ul.dropdown-select-menu {
	min-width: 130px;
	padding: 0;

	li {
		margin: 0 5px;
		padding: 5px 0;
		border-bottom: 1px solid $brand-grey;
		transition: all 0.2s;
		font-size: 10px;
		color: $brand-dark;

		a {
			padding: 0;
			color: $brand-dark-grey;
			transition: all 0.3s;

			&:hover {
				background: transparent;
			}
		}

		i {
			display: inline-block;
			width: 20px;
			text-align: center;
		}

		&:last-child {
			border-bottom: none;
		}

		&:hover {
			cursor: pointer;
			color: $brand-accent;
		}

		&.danger {
			&:hover {
				cursor: pointer;
				color: $brand-danger;
			}
		}
	}
}

.breadcrumb {
	background: none;
	margin-bottom: 0;
	padding: 0;

	> .active {
		color: $brand-accent;
		font-size: 16px;
	}

	a {
		font-size: 16px;
	}
}

.ViewTypeChangeWrapper {
	margin-bottom: 15px;

	button {
		color: $brand-dark-grey;
		line-height: 37px;
		font-size: 13px;
		padding: 0 17px;
		background: transparent;
		border: solid 1px #d5d5d5;
		font-weight: bold;
		border-radius: 0;

		i {
			font-size: 12px;
			display: inline-block;
			margin-right: 5px;
		}

		&.selected-btn {
			background: white;
			color: $brand-accent;
			box-shadow: 0 1px 4px 0 rgba(255, 163, 29, 0.34);
			border-color: transparent;
		}
	}
}

.ComingSoon {
	height: 100%;

	.ComingSoon__header {
		text-transform: uppercase;
		background: rgb(255, 191, 0);
		padding: 20px;
		font-family: $font-brand;
		font-size: 25px;
		font-weight: 900;
		line-height: 1.3;
		color: #ffffff;
	}

	.ComingSoon__btn {
		padding: 0 70px;
		color: rgb(255, 191, 0);
		line-height: 43px;
		border-radius: 4px;
		background-color: #ffffff;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.21);
	}
	.ComingSoon__content {
		overflow: auto;
		padding-top: 20px;

		img {
			width: 100%;
		}
	}
}

.VenueEventTooltip {
	background: white;
	box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
	border-left: 3px solid $brand-accent;
	min-width: 260px;

	.VenueEventTooltip__top {
		padding: 10px;
		background-color: rgb(255, 242, 222);

		h5 {
			font-size: 12px;
			line-height: 12px;
			color: $brand-accent;
			margin-bottom: 5px;
		}

		p {
			font-size: 12px;
			color: $brand-black;
		}
	}

	.VenueEventTooltip__content {
		padding: 10px;
	}
}

.EventTooltipItem {
	.EventTooltipItem__header {
		font-size: 10px;
		font-weight: 300;
		line-height: 17px;
		text-align: left;
		color: $brand-dark-grey;
	}

	.EventTooltipItem__content {
		color: $brand-black;
		font-size: 10px;

		b {
			font-size: 12px;
		}
	}
}

.tooltipster-box {
	border-radius: 0 !important;
	border: none !important;
	padding: 0;
	background: transparent !important;

	.tooltipster-content {
		padding: 0;
		overflow: visible !important;
	}
}

.inner-page .inner-page__content {
	height: calc(100% - 96px);
	padding-left: 2vw;
	padding-right: 2vw;
}

.program-det-header {
	display: flex;
	width: 100%;
	height: 70px;
	align-items: center;
	background-color: #f3f7fa;
	padding-left: 5vw;

	h3 {
		color: $brand-primary;
		font-size: 16px;
		font-weight: bold;
		font-family: $font-brand;
		margin-top: 0;
		margin-bottom: 0;
	}
}

.flex-form {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding-left: 4vw;
	padding-right: 4vw;
}

.header-and-info-row {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.flex-item {
	padding: 15px;
	min-height: 145px;

	.inside-item-flex {
		display: flex;
		flex-direction: column;
	}
}

.body-padding {
	padding: 3vh 2vw 0 2vw;
}

.full-height {
	height: 100%;
}

.scrollable-content {
	overflow-y: auto;
	scroll-behavior: smooth;
}

.scrollable {
	overflow-y: auto;
	scroll-behavior: smooth;
}

.flex-spread-far {
	display: flex !important;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.body-scroll {
	height: 100%;
	overflow-y: auto;
}

.flex-end-only {
	display: flex;
	justify-content: flex-end;
}

.full-width {
	width: 100%;
}

.accent-button {
	background-color: $brand-accent !important;
	border-color: $brand-accent !important;
}

.accent-button:hover {
	background-color: $brand-accent-dark !important;
	border-color: $brand-accent-dark !important;
}

.colored-input {
	input {
		background-color: #f3f7fa;
	}
	textarea {
		background-color: #f3f7fa;
	}
}

.bold-text {
	font-weight: bold;
}

.rounded-corners {
	border-radius: 8px;
}

.success-button {
	background-color: $brand-success;
	border-color: $brand-success;

	&:hover {
		background-color: darken($brand-success, 10%);
		border-color: darken($brand-success, 10%);
	}
}

.flex-no-grow {
	flex-grow: 0 !important;
}

.flex-grow-1 {
	flex-grow: 1 !important;
}

.switch-padding {
	padding-right: 16px;
}

.half-row-button {
	width: 100%;

	button {
		width: 100%;
	}
}

.stepper-header {
	min-height: 70px !important;
}

.elevation--2 {
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.elevation--4 {
	box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.elevation--6 {
	box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.elevation--8 {
	box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.full-screen-container {
	height: 100vh;
	width: 100%;
}

.ng-select-container {
	border-color: #f7c500 !important;
}

.ng-dropdown-panel {
	min-width: fit-content;
}

.input-group {
	//display: block !important;
}
