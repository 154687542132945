.table {
	width: 100%;

	thead {
		border-bottom: 2px solid $brand-accent;
		padding-bottom: 5px;

		tr {
			color: $brand-dark;
			font-weight: 500;
		}

		td {
			padding: 10px 0;
		}
	}

	tbody {
		td {
			padding: 10px 0;
		}
	}

	&.table--has-checkbox {
		tbody,
		thead {
			td {
				&:first-child {
					width: 30px;
				}
			}
		}
	}
}

.rc-datatable.ngx-datatable {
	background: transparent;
	box-shadow: none;

	.datatable-header {
		border-bottom: none;

		.datatable-header-cell {
			color: $brand-black;
			font-size: 12px;
			font-weight: 500;

			&.sort-active {
				color: $brand-black;
				font-weight: bold;
			}
		}

		.datatable-checkbox {
			input[type='checkbox']:after {
				display: none;
			}
		}

		.datatable-row-center {
			.datatable-header-cell:first-child {
				padding-left: 8px !important;
				padding-right: 6px !important;
			}
		}
	}

	.datatable-body-row {
		min-height: 50px;
		align-items: center;
		display: flex;
		border-left: 2px solid transparent;

		&.active {
			border-left: 2px solid $brand-accent;
		}

		.datatable-body-cell {
			display: flex;
			align-items: center;
		}

		&.datatable-row-even {
			background: #fbfbfb;
		}

		&.datatable-row-odd {
			background: #ececec;
		}
	}

	.text-center {
		justify-content: center;
		text-align: center !important;

		.datatable-body-cell-label {
			text-align: center;
		}
	}

	.datatable-body-cell.checkbox-cell,
	.datatable-header-cell.checkbox-cell {
		padding-left: 8px !important;
		padding-right: 6px !important;
	}

	.datatable-checkbox {
		padding: 0;

		input[type='checkbox']:before {
			width: 14px;
			height: 14px;
			border-radius: 2px;
			background-color: #ffffff;
			border: solid 1px $brand-accent;
			transition: all 0.1s ease-in-out;
			top: -1px;
		}

		input[type='checkbox'],
		input[type='checkbox']:focus {
			outline: none;
		}

		input[type='checkbox']:after {
			content: '';
			display: block;
			width: 4px;
			height: 8px;
			border: solid white;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
			z-index: 1;
			visibility: hidden;
			background: $brand-accent;
			left: 5px;
			top: 1px;
			transition: all 0.1s ease-in-out;
		}

		input[type='checkbox']:checked:before {
			transform: rotate(0);
			background: $brand-accent;
		}

		input[type='checkbox']:checked:after {
			visibility: visible;
		}
	}
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
	background-color: transparent;
}
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover {
	background-color: rgba(255, 163, 29, 0.12);
}
