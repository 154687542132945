.page-sub-navigation {
	background: white;

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		a {
			color: $brand-grey;
			font-size: 14px;
			font-weight: 600;
		}

		> li {
			height: 100%;
			display: inline-block;
			margin-right: 60px;
			position: relative;
			line-height: 60px;

			a.active {
				color: $brand-abs-black;
				font-size: 14px;
				position: relative;
				display: inline-block;

				&::after {
					content: '';
					position: absolute;
					width: 100%;
					height: 3px;
					background: $brand-accent;
					left: 0;
					bottom: 0;
				}
			}

			&:hover {
				.nav-dropdown {
					display: block;
				}
			}
		}
	}
}

.nav-dropdown {
	position: absolute;
	top: 44px;
	min-width: 195px;
	z-index: 2;
	left: -50%;
	display: none;
	padding-top: 35px;

	ul {
		background: white;
		position: relative;
		box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);

		&::before {
			content: '';
			position: absolute;
			top: -10px;
			left: 0;
			right: 0;
			margin: 0 auto;
			width: 0;
			height: 0;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 10px solid white;
		}
	}

	li {
		margin: 0;
		transition: background-color 0.3s;
		line-height: 15px;

		a {
			text-transform: capitalize;
			display: inline-block;
			padding: 10px;
			font-weight: normal;
			font-size: 12px;
			color: $brand-black;
			transition: color 0.3s;
		}

		&:not(.list-btn):hover {
			background-color: rgba(255, 163, 29, 0.1);
			border-left: 3px solid $brand-accent;

			a {
				padding-left: 7px;
			}
		}

		&.list-btn {
			padding: 5px;

			a {
				padding: 5px;
				color: white;
			}

			text-align: center;
		}
	}
}
