.page-header {
	margin-bottom: 12px;
	margin-top: 0;
	margin-left: 0;
	marign-right: 0;

	h3 {
		font-size: 18px;
		font-weight: bold;
		font-family: $font-brand;
		color: $brand-black;
	}

	.button {
		margin-top: 14px;
	}

	.top-wrapper {
		min-height: 25px;
	}

	&.page-header--no-margin {
		margin-bottom: 0;
	}

	&.page-header--no-border {
		border-bottom: none;
	}
}

.page-content {
	overflow: auto;
}
