.LabeledFeedWidget {
	.LabeledFeedWidget__top {
		position: relative;
	}

	.LabeledFeedWidget__image {
		margin: 0 auto;
		width: 252px;
		height: 140px;
		background-size: contain;
		background-position: center;
	}

	.LabeledFeedWidget__content {
		h5 {
			text-align: center;
			font-family: $font-brand;
			font-size: 24px;
			font-weight: 900;
			text-transform: uppercase;
		}
	}
}

.LabeledFeedWidget {
	&.LabeledFeedWidget--level-up {
		.LabeledFeedWidget__level-up__rank {
			font-family: $font-brand;
			font-weight: bold;
			font-size: 35px;
			text-align: center;
			color: white;
			position: absolute;
			left: 0;
			right: 0;
			top: 64px;
		}

		.LabeledFeedWidget__level-up__points {
			text-align: center;
			font-size: 10px;
			font-weight: 900;
			left: 0;
			right: 0;
			top: 95px;
			font-family: $font-brand;
			color: white;
			position: absolute;
		}
	}

	.CTAActionButton {
		line-height: 34px;
		border-radius: 4px;
		background-color: #ffffff;
		box-shadow: 0 2px 10px 0 rgba(74, 144, 226, 0.39);
		padding: 0 15px;
		color: $brand-blue;
		display: inline-block;
		font-weight: bold;
		font-family: $font-brand;
		margin-bottom: 19px;

		span {
			display: inline-block;
			margin-left: 10px;
			border-radius: 100%;
			width: 21px;
			height: 21px;
			border: 2px solid $brand-blue;
			line-height: 16px;
			text-align: center;

			i {
				font-size: 11px;
			}
		}
	}
}
