html,
body {
	min-height: 100%;
	height: 100%;
}

body {
	min-height: 600px;
}

.bottom-item-border {
	border-bottom: 1px solid $brand-light-grey;
}

.inner-page-bg {
	background: $brand-extra-light-grey;
}

.image-wrapper {
	background-size: cover;
	background-position: center;
	display: inline-block;

	&.image--round {
		border-radius: 100%;
	}

	&.image--border {
		border: 4px solid white;
	}

	&.image--shadow {
		box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.1);
	}

	&.image--responsive {
		width: 100%;
	}
}

.bottom-divider {
	border-bottom: 1px solid $brand-light-grey;
}

.rc-tooltip {
	display: inline-block;
	width: 12px;
	height: 12px;
	border-radius: 100%;
	background: $brand-dark-grey;
	color: white;
	text-align: center;
	line-height: 9px;

	.tooltip-inner {
		font-size: 11px;
		font-family: $font-default;
	}

	i {
		font-size: 8px;
	}
}

.tooltip-inner {
	background: rgba(255, 163, 29, 0.1);
	color: $brand-accent;
	padding: 10px;
	width: 150px;
	font-size: 11px;
	line-height: 1.4;
	text-align: left;
	font-family: $font-default;
	font-weight: 200;
}

.tooltip.right .tooltip-arrow,
.tooltip-arrow {
	border-right-color: rgba(255, 163, 29, 0.1);
}
