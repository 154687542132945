.modal-backdrop {
	background: #4a4a4a;

	&.in {
		opacity: 0.83;
	}
}

.rc-modal {
	margin-top: 140px;

	.modal-content {
		border-radius: 0;
		box-shadow: 0 6px 11px 0 rgba(73, 73, 73, 0.5);
	}

	.modal-footer {
		border-top: none;

		.left-action-button {
			font-size: 14px;
			color: $brand-black;
			font-weight: bold;
		}
	}

	.modal-header {
		height: 85px;

		&.modal-header--assertive {
			background: $brand-accent;
		}

		&.modal-header--success {
			background: $brand-success;
		}

		.close {
			color: white;
			opacity: 1;
		}

		.modal-header__content {
			i {
				margin-top: 8px;
				color: white;
				text-align: center;
				display: block;
				font-size: 24px;
			}

			h5 {
				text-align: center;
				color: white;
				font-size: 16px;
				font-weight: bold;
				font-family: $font-brand;
			}
		}
	}

	.modal-body {
		height: 386px;
		overflow: auto;
	}

	.modal-footer {
		.cancel-btn {
			font-family: $font-default;
			font-size: 14px;
		}
	}
}
