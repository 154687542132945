.rc-calendar {
	.fc-widget-content .fc-scroller {
		height: auto !important;
	}

	.fc-unthemed thead {
		border-color: transparent;
	}

	.fc-head-container.fc-widget-header {
		border-top: 3px solid $brand-accent;

		th {
			padding: 20px 0;
			background-color: rgba(255, 163, 29, 0.2);
			text-transform: uppercase;
			color: $brand-accent;
			font-size: 16px;
			font-weight: 900;
			border-color: transparent;
		}
	}

	.fc-basic-view .fc-body .fc-row {
		min-height: 110px;
	}

	.fc-event-container {
		.fc-event {
			border: none;
			border-left: 5px solid $brand-accent;
			background-color: rgba(255, 163, 29, 0.2);
			padding: 5px;
			color: $brand-accent;
			font-weight: bold;
			font-size: 12px;
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
		}
	}

	.fc-view-container {
		.fc-day-number {
			color: #8a888a;
		}

		.fc-other-month {
			background: #efefef;
			opacity: 0.66;
		}
	}

	.fc-toolbar.fc-header-toolbar {
		border-top: 3px solid $brand-accent;
		margin-bottom: 0;

		h2 {
			line-height: 50px;
			text-transform: uppercase;
			font-size: 16px;
			font-weight: bold;
			color: $brand-accent;
			margin-left: 0;
		}

		.fc-next-button,
		.fc-prev-button {
			font-size: 17px;
			position: relative;
			top: 9px;
			background: transparent;
			border-color: transparent;
			box-shadow: none;
			color: $brand-accent;
			line-height: 7px;
			height: 33px;
			margin-left: 0;
			margin-right: 0;
		}

		.fc-agendaWeek-button,
		.fc-agendaDay-button,
		.fc-month-button {
			position: relative;
			top: 8px;
			font-size: 16px;
			color: $brand-dark-grey;
			background: transparent;
			border: none;
			box-shadow: none;
			text-transform: uppercase;

			&.fc-state-active {
				box-shadow: 0 1px 4px 0 rgba(255, 163, 29, 0.34);
				color: $brand-accent;
				font-weight: bold;
			}
		}
	}

	.fc-axis {
		border: none;
		border-top-style: none !important;
		text-transform: uppercase;
		font-size: 11px;
		font-weight: bold;
		font-family: $font-brand;
		color: $brand-dark-grey;
	}

	.fc-time-grid-container {
		.fc-slats {
			tbody tr {
				td {
					height: 30px;
				}
			}
		}
	}
}
