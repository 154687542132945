.list {
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		margin-bottom: 8px;
	}

	&.list--icon {
		i {
			position: relative;
			top: 2px;
			text-align: center;
			margin-right: 10px;
			display: inline-block;
			width: 15px;
			font-size: 14px;
		}
	}

	&.list--single-line {
		li {
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&.list--inline {
		li {
			display: inline-block;
		}
	}
}
