$brand-primary: #0d4774;
$brand-secondary: #3f51b5;
$brand-accent: #f7c500;
$brand-accent-dark: rgb(166, 124, 0);
$brand-accent-light: rgb(191, 155, 48);
$brand-light-grey: #f3f7fa;
$brand-grey: #c6c6c6;
$brand-dark-grey: #989898;
$brand-extra-light-grey: #f6f6f6;
$brand-dark: #646464;
$brand-black: #3a3735;
$brand-abs-black: #020202;
$brand-white: #fff;

$brand-blue: #0d4774;
$brand-red: #e02020;

$brand-danger: $brand-red;
$brand-success: #52c483;
