@font-face {
	font-family: 'Brandon';
	font-weight: bold;
	src: url('/assets/fonts/Brandon/1114474/c12a6d37-72c1-408b-9ca9-32946956c57f.eot?#iefix');
	src: url('/assets/fonts/Brandon/1114474/c12a6d37-72c1-408b-9ca9-32946956c57f.eot?#iefix') format('eot'),
		url('/assets/fonts/Brandon/1114474/df1cd944-a6a3-4d30-8fd3-391c2e9531f1.woff2') format('woff2'),
		url('/assets/fonts/Brandon/1114474/4f2d0548-c7dc-459b-a2e0-e86656a10ded.woff') format('woff'),
		url('/assets/fonts/Brandon/1114474/dc21d9e5-516f-4e7e-9a84-7be79a44c2dd.ttf') format('truetype');
}

@font-face {
	font-family: 'Brandon';
	font-weight: 900;
	src: url('/assets/fonts/Brandon/1114480/b7e10dd9-521c-43c5-a17e-0bb4a5028f6f.eot?#iefix');
	src: url('/assets/fonts/Brandon/1114480/b7e10dd9-521c-43c5-a17e-0bb4a5028f6f.eot?#iefix') format('eot'),
		url('/assets/fonts/Brandon/1114480/5eb8ec44-bd3d-4f8e-a022-4435afe8c772.woff2') format('woff2'),
		url('/assets/fonts/Brandon/1114480/f74a4823-b1ad-4887-9f8e-b02719f2972b.woff') format('woff'),
		url('/assets/fonts/Brandon/1114480/bb746ead-509f-451a-b786-cbb81b93710d.ttf') format('truetype');
}

@font-face {
	font-family: 'Brandon';
	font-weight: normal;
	src: url('/assets/fonts/Brandon/1114498/49d8a110-dd06-4918-9fea-516c27058edf.eot?#iefix');
	src: url('/assets/fonts/Brandon/1114498/49d8a110-dd06-4918-9fea-516c27058edf.eot?#iefix') format('eot'),
		url('/assets/fonts/Brandon/1114498/1e5fc8fa-a98c-4613-a08e-c6c975759a80.woff2') format('woff2'),
		url('/assets/fonts/Brandon/1114498/6b45f9ca-22b4-48ca-962f-6ff1fa7fc196.woff') format('woff'),
		url('/assets/fonts/Brandon/1114498/7fdcfbf6-62ea-4d31-935f-cac99a996c73.ttf') format('truetype');
}

@font-face {
	font-family: 'rc-icons';
	src: url('/assets/fonts/rc-icons/rc-icons.eot?ksnvat');
	src: url('/assets/fonts/rc-icons/rc-icons.eot?ksnvat#iefix') format('embedded-opentype'),
		url('/assets/fonts/rc-icons/rc-icons.ttf?ksnvat') format('truetype'),
		url('/assets/fonts/rc-icons/rc-icons.woff?ksnvat') format('woff'),
		url('/assets/fonts/rc-icons/rc-icons.svg?ksnvat#rc-icons') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'icons-rc-outline';
	src: url('/assets/fonts/rc-icons/icons-rc-outline.ttf?ksnvat') format('truetype'),
		url('/assets/fonts/rc-icons/icons-rc-outline.woff?ksnvat') format('woff'),
		url('/assets/fonts/rc-icons/icons-rc-outline.svg?ksnvat#icons-rc-outline') format('svg');
	font-weight: normal;
	font-style: normal;
}
