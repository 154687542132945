[class^='icon-outline'],
[class*=' icon-outline'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icons-rc-outline' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-outlineicn-sport-roller-derby-outline .path1:before {
	content: '\e96b';
	color: $brand-accent;
}
.icon-outlineicn-sport-roller-derby-outline .path2:before {
	content: '\e96c';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-catchball-outline .path1:before {
	content: '\e969';
	color: $brand-accent;
}
.icon-outlineicn-sport-catchball-outline .path2:before {
	content: '\e96a';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-blitzball-outline .path1:before {
	content: '\e967';
	color: $brand-accent;
}
.icon-outlineicn-sport-blitzball-outline .path2:before {
	content: '\e968';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-outline-baseball2 .path1:before {
	content: '\e965';
	color: $brand-accent;
}
.icon-outlineicn-sport-outline-baseball2 .path2:before {
	content: '\e966';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-adventure .path1:before {
	content: '\e926';
	color: $brand-accent;
}
.icon-outlineicn-sport-adventure .path2:before {
	content: '\e927';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-badmington .path1:before {
	content: '\e928';
	color: $brand-accent;
}
.icon-outlineicn-sport-badmington .path2:before {
	content: '\e929';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-boxing .path1:before {
	content: '\e92a';
	color: $brand-accent;
}
.icon-outlineicn-sport-boxing .path2:before {
	content: '\e92b';
	margin-left: -1em;
	color: $brand-accent;
}
.icon-outlineicn-sport-boxing .path3:before {
	content: '\e92c';
	margin-left: -1em;
	color: $brand-accent;
}
.icon-outlineicn-sport-boxing .path4:before {
	content: '\e92d';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-broomball .path1:before {
	content: '\e92e';
	color: $brand-accent;
}
.icon-outlineicn-sport-broomball .path2:before {
	content: '\e92f';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-cricket .path1:before {
	content: '\e930';
	color: $brand-accent;
}
.icon-outlineicn-sport-cricket .path2:before {
	content: '\e931';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-crossfit .path1:before {
	content: '\e932';
	color: $brand-accent;
}
.icon-outlineicn-sport-crossfit .path2:before {
	content: '\e933';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-cycling .path1:before {
	content: '\e934';
	color: $brand-accent;
}
.icon-outlineicn-sport-cycling .path2:before {
	content: '\e935';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-dance .path1:before {
	content: '\e936';
	color: $brand-accent;
}
.icon-outlineicn-sport-dance .path2:before {
	content: '\e937';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-field-hockey .path1:before {
	content: '\e938';
	color: $brand-accent;
}
.icon-outlineicn-sport-field-hockey .path2:before {
	content: '\e939';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-fitness .path1:before {
	content: '\e93a';
	color: $brand-accent;
}
.icon-outlineicn-sport-fitness .path2:before {
	content: '\e93b';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-golf .path1:before {
	content: '\e93c';
	color: $brand-accent;
}
.icon-outlineicn-sport-golf .path2:before {
	content: '\e93d';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-golf .path3:before {
	content: '\e93e';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-handball .path1:before {
	content: '\e93f';
	color: $brand-accent;
}
.icon-outlineicn-sport-handball .path2:before {
	content: '\e940';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-kickboxing .path1:before {
	content: '\e941';
	color: $brand-accent;
}
.icon-outlineicn-sport-kickboxing .path2:before {
	content: '\e942';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-martial-arts .path1:before {
	content: '\e943';
	color: $brand-accent;
}
.icon-outlineicn-sport-martial-arts .path2:before {
	content: '\e944';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-outdoors .path1:before {
	content: '\e945';
	color: $brand-accent;
}
.icon-outlineicn-sport-outdoors .path2:before {
	content: '\e946';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-pilates .path1:before {
	content: '\e947';
	color: $brand-accent;
}
.icon-outlineicn-sport-pilates .path2:before {
	content: '\e948';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-racquetball .path1:before {
	content: '\e949';
	color: $brand-accent;
}
.icon-outlineicn-sport-racquetball .path2:before {
	content: '\e94a';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-rowing .path1:before {
	content: '\e94b';
	color: $brand-accent;
}
.icon-outlineicn-sport-rowing .path2:before {
	content: '\e94c';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-running .path1:before {
	content: '\e94d';
	color: $brand-accent;
}
.icon-outlineicn-sport-running .path2:before {
	content: '\e94e';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-sailing .path1:before {
	content: '\e94f';
	color: $brand-accent;
}
.icon-outlineicn-sport-sailing .path2:before {
	content: '\e950';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-skiing .path1:before {
	content: '\e951';
	color: $brand-accent;
}
.icon-outlineicn-sport-skiing .path2:before {
	content: '\e952';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-snowboard .path1:before {
	content: '\e953';
	color: $brand-accent;
}
.icon-outlineicn-sport-snowboard .path2:before {
	content: '\e954';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-spining .path1:before {
	content: '\e955';
	color: $brand-accent;
}
.icon-outlineicn-sport-spining .path2:before {
	content: '\e956';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-squash .path1:before {
	content: '\e957';
	color: $brand-accent;
}
.icon-outlineicn-sport-squash .path2:before {
	content: '\e958';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-sup .path1:before {
	content: '\e959';
	color: $brand-accent;
}
.icon-outlineicn-sport-sup .path2:before {
	content: '\e95a';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-surfing .path1:before {
	content: '\e95b';
	color: $brand-accent;
}
.icon-outlineicn-sport-surfing .path2:before {
	content: '\e95c';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-swimming .path1:before {
	content: '\e95d';
	color: $brand-accent;
}
.icon-outlineicn-sport-swimming .path2:before {
	content: '\e95e';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-triathlon .path1:before {
	content: '\e95f';
	color: $brand-accent;
}
.icon-outlineicn-sport-triathlon .path2:before {
	content: '\e960';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-wind-surfing .path1:before {
	content: '\e961';
	color: $brand-accent;
}
.icon-outlineicn-sport-wind-surfing .path2:before {
	content: '\e962';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-yoga .path1:before {
	content: '\e963';
	color: $brand-accent;
}
.icon-outlineicn-sport-yoga .path2:before {
	content: '\e964';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-multi .path1:before {
	content: '\e924';
	color: $brand-accent;
}
.icon-outlineicn-sport-multi .path2:before {
	content: '\e925';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-baseball .path1:before {
	content: '\e900';
	color: $brand-accent;
}
.icon-outlineicn-sport-baseball .path2:before {
	content: '\e901';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-basketball .path1:before {
	content: '\e902';
	color: $brand-accent;
}
.icon-outlineicn-sport-basketball .path2:before {
	content: '\e903';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-bocce-ball .path1:before {
	content: '\e904';
	color: $brand-accent;
}
.icon-outlineicn-sport-bocce-ball .path2:before {
	content: '\e905';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-bowling .path1:before {
	content: '\e906';
	color: $brand-accent;
}
.icon-outlineicn-sport-bowling .path2:before {
	content: '\e907';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-cornhole .path1:before {
	content: '\e908';
	color: $brand-accent;
}
.icon-outlineicn-sport-cornhole .path2:before {
	content: '\e909';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-dodgeball .path1:before {
	content: '\e90a';
	color: $brand-accent;
}
.icon-outlineicn-sport-dodgeball .path2:before {
	content: '\e90b';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-football .path1:before {
	content: '\e90c';
	color: $brand-accent;
}
.icon-outlineicn-sport-football .path2:before {
	content: '\e90d';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-frisbee .path1:before {
	content: '\e90e';
	color: $brand-accent;
}
.icon-outlineicn-sport-frisbee .path2:before {
	content: '\e90f';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-hockey .path1:before {
	content: '\e910';
	color: $brand-accent;
}
.icon-outlineicn-sport-hockey .path2:before {
	content: '\e911';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-kickball .path1:before {
	content: '\e912';
	color: $brand-accent;
}
.icon-outlineicn-sport-kickball .path2:before {
	content: '\e913';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-lacrosse .path1:before {
	content: '\e914';
	color: $brand-accent;
}
.icon-outlineicn-sport-lacrosse .path2:before {
	content: '\e915';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-pingpong .path1:before {
	content: '\e916';
	color: $brand-accent;
}
.icon-outlineicn-sport-pingpong .path2:before {
	content: '\e917';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-rugby .path1:before {
	content: '\e918';
	color: $brand-accent;
}
.icon-outlineicn-sport-rugby .path2:before {
	content: '\e919';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-skeeball .path1:before {
	content: '\e91a';
	color: $brand-accent;
}
.icon-outlineicn-sport-skeeball .path2:before {
	content: '\e91b';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-soccer .path1:before {
	content: '\e91c';
	color: $brand-accent;
}
.icon-outlineicn-sport-soccer .path2:before {
	content: '\e91d';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-tennis .path1:before {
	content: '\e91e';
	color: $brand-accent;
}
.icon-outlineicn-sport-tennis .path2:before {
	content: '\e91f';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-volleyball .path1:before {
	content: '\e920';
	color: $brand-accent;
}
.icon-outlineicn-sport-volleyball .path2:before {
	content: '\e921';
	margin-left: -1em;
	color: white;
}
.icon-outlineicn-sport-wiffle-ball .path1:before {
	content: '\e922';
	color: $brand-accent;
}
.icon-outlineicn-sport-wiffle-ball .path2:before {
	content: '\e923';
	margin-left: -0.9912109375em;
	color: white;
}
.icon-outlineicn-sport-ice-skating .path1:before {
	content: '\e970';
	color: $brand-accent;
}
.icon-outlineicn-sport-ice-skating .path2:before {
	content: '\e970';
	margin-left: -0.9912109375em;
	color: white;
}
