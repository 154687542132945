.box-top-border {
	border-top: 5px solid $brand-accent;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;

	&.border-top-border--muted {
		border-top: 5px solid $brand-dark-grey;
	}

	&.box-top-border--small {
		border-top: 3px solid $brand-accent;
	}

	&.box-top-border--small-muted {
		border-top: 3px solid $brand-dark-grey;
	}
}

.box-item {
	border-radius: 2px;
	background: white;
	overflow: hidden;
	transition: all 0.3s;
	width: 100%;
	margin-bottom: 15px;

	&.box--shadow {
		box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.1);
	}

	&.box--shadow-small {
		box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
	}

	&.box--padding {
		padding: 13px;
	}

	&.box--border-light {
		border: solid 0.5px #f2f2f2;
	}

	&.box--border {
		border: solid 1px $brand-grey;
	}

	.box-text {
		font-size: 11px;
		color: $brand-dark-grey;
	}

	.box-title {
		margin-top: 0;

		i {
			margin-right: 10px;
		}
	}

	.collapse-btn {
		float: right;
		position: relative;
		top: -7px;
		transition: transform 0.15s;

		i {
			font-size: 24px;
			color: $brand-dark;
		}
	}

	&.box--collapsable {
		height: 40px;

		.box-header {
			cursor: pointer;
		}

		&.box--collapsable-active {
			height: auto;
			background: inherit;

			.box-title {
				color: $brand-accent;

				.collapse-btn {
					transform: rotate(180deg);
				}
			}
		}

		&.box--collapsable--checkbox {
			&.box--collapsable-active {
				color: $brand-dark;
			}

			.box-title {
				color: $brand-dark;

				.checkbox {
					width: 19px;
					display: inline-block;
				}
			}
		}
	}

	&.box-item--empty-results {
		min-height: 150px;
		color: $brand-grey;

		.box-item--empty-results__icon {
			margin-top: 30px;
			text-align: center;

			i {
				font-size: 50px;
			}
		}

		.box-item--empty-results__text {
			font-size: 13px;
		}
	}

	&.box-item--form {
		.box-content {
			padding-top: 15px;
		}
	}
}

.BrandBoxGlow {
	box-shadow: 0 6px 11px 0 rgba(255, 163, 29, 0.34);
	border: solid 0.5px #f2f2f2;
}
