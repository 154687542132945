.form,
form {
	.form__section {
		padding-top: 10px;

		.form__section__header {
			color: $brand-accent;
			font-weight: bold;
			border-bottom: 1px solid $brand-light-grey;
			margin-bottom: 5px;
			text-transform: uppercase;

			&.form__section__header--no-border {
				border-bottom: none;
			}
		}

		.form__section__helper {
			color: $brand-dark;
			line-height: 1.37;
			margin-bottom: 15px;
		}
	}

	.input-group {
		margin-bottom: 15px;
		position: relative;

		label i {
			&.icon-rc-short-des {
				font-size: 6px;
			}

			&.icon-rc-business {
				font-size: 15px;
			}

			&.fa {
				font-size: 16px;
			}
		}

		.input:not(textarea) {
			padding: 8px;
		}

		&.has-error {
			input,
			textarea {
				border-color: $brand-danger;
			}
		}

		.error-message {
			font-size: 10px;
			color: $brand-danger;
		}

		label {
			display: block;
			font-size: 11px;

			span {
				font-size: 10px;
				font-weight: 300;
				color: $brand-dark-grey;
			}
		}

		.rc-tooltip {
			position: absolute;
			right: -19px;
			top: 10px;
		}

		.input-wrapper {
			position: relative;
		}
	}
}

.rc-tooltip-white {
	.tooltip-inner {
		background: white !important;
		width: auto;
		color: $brand-dark-grey;
		font-size: 12px;
		padding: 5px 10px;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
		border: solid 1px #ededed;
	}

	.tooltip.right .tooltip-arrow,
	.tooltip-arrow {
		border-right-color: white !important;
		border-bottom-color: white !important;
		border-top-color: white !important;
	}
}

.SettingsBtnRowToggle {
	position: relative;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 6px 11px 0 rgba(255, 163, 29, 0.34);
	border: solid 0.5px #f2f2f2;
	margin-bottom: 20px;

	&:hover {
		cursor: pointer;
	}

	&.SettingsBtnRowToggle--active {
		box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.1);

		.SettingsBtnRowToggle__checkbox {
			display: block;
		}
	}

	span {
		font-weight: bold;
		font-size: 12px;
		color: $brand-accent;
	}

	.SettingsBtnRowToggle__checkbox {
		display: none;
		position: absolute;
		top: -12px;
		width: 25px;
		height: 25px;
		border-radius: 100%;
		background: $brand-accent;
		text-align: center;

		i {
			font-size: 12px;
			color: white;
			position: relative;
			top: 4px;
		}
	}
}

.SelectableFormRow {
	border: solid 1px #d5d5d5;
	min-height: 62px;
	display: flex;
	padding: 18px 20px;
	font-size: 12px;
	color: $brand-dark-grey;
	font-weight: bold;
	align-items: center;
	margin-bottom: 14px;

	&:hover {
		cursor: pointer;
	}

	.SelectableFormRow__side-label {
		margin-left: auto;
	}

	.SelectableFormRow__label {
		font-weight: bold;
		text-transform: uppercase;
	}

	&.SelectableFormRow--active {
		box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.1);
		color: $brand-black;
	}

	&.SelectableFormRow--no-checkbox {
		.SelectableFormRow__label {
			margin-left: 25px;
		}
	}
}
