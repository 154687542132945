.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
	padding-left: 10px;
	padding-right: 10px;
}

.row {
	margin-left: -10px;
	margin-right: -10px;
}

.container-fluid {
	height: 100%;
	padding-left: 10px;
	padding-right: 10px;
}

.inner-page {
	height: 100%;

	.inner-page__content {
		height: calc(100% - 88px);
		overflow: auto;

		&.inner-page__content__sub-navigation {
			height: calc(100% - 82px);
		}
		&.inner-page__content--background {
			background: $brand-extra-light-grey;
		}

		&.inner-page__content--has-bottom-nav {
			height: calc(100% - 98px);
		}

		&.inner-page__content-left-aligned {
			> .col-md-12 {
				> .WizardForm {
					margin-left: -10px;
				}
			}
		}
	}
}

.bottom-nav-bar {
	position: relative;
	z-index: 1;
	height: 50px;
	background-color: white;
	box-shadow: 3px 0 4px 0 rgba(155, 154, 155, 0.31);

	.bottom-nav-bar__inner__content--full {
		max-width: none !important;
		padding-right: 20px !important;
	}
}
