.DashboardWidget {
	.DashboardWidget__title {
		a {
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	.DashboardWidget__content {
		padding-top: 15px;
		display: flex;
		width: 100%;
	}

	.DashboardWidget__content__item {
		width: 100%;

		&.DashboardWidget__content__item--actions {
			width: 130px;
			min-width: 130px;
			margin-right: 15px;
		}
	}
}

.EntityPublishLabel {
	color: $brand-dark;
	font-size: 11px;
	display: inline-block;

	i {
		border-radius: 100%;
		background: $brand-dark;
		display: inline-block;
		width: 6px;
		height: 6px;
		margin-right: 5px;
	}

	&.EntityPublishLabel--draft i {
		background: #f99400;
	}

	&.EntityPublishLabel--published i {
		background: $brand-success;
	}

	&.EntityPublishLabel--boxed {
		border-radius: 2px;
		background-color: #ffffff;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
		padding: 2px 5px;
	}
}
