.cards-slider {
	.carousel-wrapper {
		display: flex;

		> :last-child {
			flex-grow: 1;
		}

		> :first-child {
			padding-left: 0;
		}
	}

	.item {
		padding: 0 10px 10px 10px;
	}

	.owl-stage-outer {
		margin-left: -10px;
		margin-right: 30px;
	}

	.owl-nav {
		display: block !important;

		.owl-next {
			position: absolute;
			right: 0;
			height: 100%;
			top: 0;
			text-indent: -9999px;
			white-space: nowrap;
			width: 50px;

			&::before {
				text-indent: 0;
				content: '\f054';
				font-family: FontAwesome;
				display: inline-block;
				font-size: 17px;
				color: #979797;
				width: 18px;
				height: 18px;
				right: 0;
				top: 50%;
				position: absolute;
				z-index: 2;
				transform: translateY(-25px);
			}
		}

		.owl-prev {
			display: none;
		}
	}
}
