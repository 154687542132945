.color-brand-accent {
	color: $brand-accent;
}

.color-brand-dark-grey {
	color: $brand-dark-grey;
}

.color-brand-success {
	color: $brand-success;
}
