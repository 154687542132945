/**
 PLEASE MAKE SURE ICON-RC-CALENDAR IS CORRECTLY SPELLED WHEN LOADING ICONS FROM ICOOMOON
 */

[class^='icon-'],
[class*=' icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'rc-icons' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-icn-sport-ice-skating:before {
	content: '\e900';
}
.icon-rc-user-switch:before {
	content: '\e901';
}
.icon-rc-event-liked:before {
	content: '\e903';
}
.icon-rc-event-like:before {
	content: '\e904';
}
.icon-rc-feed-comment:before {
	content: '\e905';
}
.icon-rc-league-like:before {
	content: '\e906';
}
.icon-rc-league-liked:before {
	content: '\e907';
}
.icon-rc-add-small:before {
	content: '\e908';
}
.icon-rc-c-small:before {
	content: '\e909';
}
.icon-rc-c:before {
	content: '\e90a';
}
.icon-rc-comment:before {
	content: '\e90b';
}
.icon-rc-edit:before {
	content: '\e90c';
}
.icon-rc-filter-small:before {
	content: '\e90d';
}
.icon-rc-filter:before {
	content: '\e90e';
}
.icon-rc-like:before {
	content: '\e90f';
}
.icon-rc-liked-indication:before {
	content: '\e910';
}
.icon-rc-list-small:before {
	content: '\e911';
}
.icon-rc-list:before {
	content: '\e912';
}
.icon-rc-location:before {
	content: '\e913';
}
.icon-rc-map-small:before {
	content: '\e914';
}
.icon-rc-map:before {
	content: '\e915';
}
.icon-rc-nav-events:before {
	content: '\e916';
}
.icon-rc-nav-feed:before {
	content: '\e917';
}
.icon-rc-nav-leagues:before {
	content: '\e918';
}
.icon-rc-nav-more:before {
	content: '\e919';
}
.icon-rc-nav-venues:before {
	content: '\e91a';
}
.icon-rc-share:before {
	content: '\e91b';
}
.icon-rc-star-selected:before {
	content: '\e91c';
}
.icon-rc-verified:before {
	content: '\e91d';
}
.icon-rc-calendar:before {
	content: '\e91e';
}
.icon-rc-cost:before {
	content: '\e91f';
}
.icon-rc-location2:before {
	content: '\e920';
}
.icon-rc-coach-small:before {
	content: '\e921';
}
.icon-rc-player-small:before {
	content: '\e922';
}
.icon-rc-referee-small:before {
	content: '\e923';
}
.icon-icn-sport-baseball:before {
	content: '\e924';
}
.icon-icn-sport-basketball:before {
	content: '\e925';
}
.icon-icn-sport-bocce-ball:before {
	content: '\e926';
}
.icon-icn-sport-bowling:before {
	content: '\e927';
}
.icon-icn-sport-cornhole:before {
	content: '\e928';
}
.icon-icn-sport-dodgeball:before {
	content: '\e929';
}
.icon-icn-sport-football:before {
	content: '\e92a';
}
.icon-icn-sport-frisbee:before {
	content: '\e92b';
}
.icon-icn-sport-hockey:before {
	content: '\e92c';
}
.icon-icn-sport-kickball:before {
	content: '\e92d';
}
.icon-icn-sport-lacrosse:before {
	content: '\e92e';
}
.icon-icn-sport-pingpong:before {
	content: '\e92f';
}
.icon-icn-sport-rugby:before {
	content: '\e930';
}
.icon-icn-sport-skeeball:before {
	content: '\e931';
}
.icon-icn-sport-soccer:before {
	content: '\e932';
}
.icon-icn-sport-tennis:before {
	content: '\e933';
}
.icon-icn-sport-volleyball:before {
	content: '\e934';
}
.icon-icn-sport-wiffle-ball:before {
	content: '\e935';
}
.icon-icn-sport-baseball-2:before {
	content: '\e936';
}
.icon-feed-liked-indicator:before {
	content: '\e937';
}
.icon-rc-share-icon:before {
	content: '\e938';
}
.icon-rc-key:before {
	content: '\e939';
}
.icon-rc-private:before {
	content: '\e93a';
}
.icon-rc-request-mail:before {
	content: '\e93b';
}
.icon-icn-sport-multi:before {
	content: '\e93c';
}
.icon-rc-binacular-icon:before {
	content: '\e93d';
}
.icon-rc-person-icon:before {
	content: '\e93e';
}
.icon-rc-star-icon:before {
	content: '\e93f';
}
.icon-rc-teams-icon:before {
	content: '\e940';
}
.icon-rc-teams-follow-icon:before {
	content: '\e941';
}
.icon-rc-teams-following-icon:before {
	content: '\e942';
}
.icon-icn-sport-adventure:before {
	content: '\e943';
}
.icon-icn-sport-badmington:before {
	content: '\e944';
}
.icon-icn-sport-boxing:before {
	content: '\e945';
}
.icon-icn-sport-broomball:before {
	content: '\e946';
}
.icon-icn-sport-cricket:before {
	content: '\e947';
}
.icon-icn-sport-crossfit:before {
	content: '\e948';
}
.icon-icn-sport-cycling:before {
	content: '\e949';
}
.icon-icn-sport-dance:before {
	content: '\e94a';
}
.icon-icn-sport-field-hockey:before {
	content: '\e94b';
}
.icon-icn-sport-fitness:before {
	content: '\e94c';
}
.icon-icn-sport-golf:before {
	content: '\e94d';
}
.icon-icn-sport-handball:before {
	content: '\e94e';
}
.icon-icn-sport-kickboxing:before {
	content: '\e94f';
}
.icon-icn-sport-martial-arts:before {
	content: '\e950';
}
.icon-icn-sport-outdoors:before {
	content: '\e951';
}
.icon-icn-sport-pilates:before {
	content: '\e952';
}
.icon-icn-sport-racquetball:before {
	content: '\e953';
}
.icon-icn-sport-rowing:before {
	content: '\e954';
}
.icon-icn-sport-running:before {
	content: '\e955';
}
.icon-icn-sport-sailing:before {
	content: '\e956';
}
.icon-icn-sport-skiing:before {
	content: '\e957';
}
.icon-icn-sport-snowboard:before {
	content: '\e958';
}
.icon-icn-sport-spining:before {
	content: '\e959';
}
.icon-icn-sport-squash:before {
	content: '\e95a';
}
.icon-icn-sport-sup:before {
	content: '\e95b';
}
.icon-icn-sport-surfing:before {
	content: '\e95c';
}
.icon-icn-sport-swimming:before {
	content: '\e95d';
}
.icon-icn-sport-triathlon:before {
	content: '\e95e';
}
.icon-icn-sport-wind-surfing:before {
	content: '\e95f';
}
.icon-icn-sport-yoga:before {
	content: '\e960';
}
.icon-icn-attending:before {
	content: '\e961';
}
.icon-icn-joined-team:before {
	content: '\e962';
}
.icon-icn-distance:before {
	content: '\e963';
}
.icon-icn-location:before {
	content: '\e964';
}
.icon-icn-near-by:before {
	content: '\e965';
}
.icon-tags:before {
	content: '\e966';
}
.icon-rc-business:before {
	content: '\e967';
}
.icon-rc-venue-liked:before {
	content: '\e968';
}
.icon-rc-liked-indication-v2:before {
	content: '\e969';
}
.icon-rc-edit-2:before {
	content: '\e96a';
}
.icon-rc-games-per-season:before {
	content: '\e96b';
}
.icon-rc-inbox:before {
	content: '\e96c';
}
.icon-rc-long-des:before {
	content: '\e96d';
}
.icon-rc-min-per-week:before {
	content: '\e96e';
}
.icon-rc-notifications2:before {
	content: '\e96f';
}
.icon-rc-payment-individual:before {
	content: '\e970';
}
.icon-rc-payment-team:before {
	content: '\e971';
}
.icon-rc-settings:before {
	content: '\e972';
}
.icon-rc-short-des:before {
	content: '\e973';
}
.icon-rc-support:before {
	content: '\e974';
}
.icon-rc-surface:before {
	content: '\e975';
}
.icon-rc-double-flag:before {
	content: '\e976';
}
.icon-rc-flag:before {
	content: '\e977';
}
.icon-rc-trophy:before {
	content: '\e978';
}
.icon-rc-whistle:before {
	content: '\e979';
}
.icon-rc-birthday:before {
	content: '\e97a';
}
.icon-rc-mail:before {
	content: '\e97b';
}
.icon-rc-mobile:before {
	content: '\e97c';
}
.icon-rc-camera:before {
	content: '\e97d';
}
.icon-rc-gallery:before {
	content: '\e97e';
}
.icon-rc-home:before {
	content: '\e97f';
}
.icon-rc-school:before {
	content: '\e980';
}
.icon-rc-pencil-edit:before {
	content: '\e981';
}
.icon-rc-facebook-square:before {
	content: '\e982';
}
.icon-rc-upload:before {
	content: '\e983';
}
.icon-rc-blog:before {
	content: '\e984';
}
.icon-rc-drag-and-drop:before {
	content: '\e985';
}
.icon-rc-instagram:before {
	content: '\e986';
}
.icon-rc-paint-brush:before {
	content: '\e987';
}
.icon-rc-ticker:before {
	content: '\e988';
}
.icon-rc-twitter:before {
	content: '\e989';
}
.icon-rc-calendar-2:before {
	content: '\e98a';
}
.icon-rc-drag-points:before {
	content: '\e98b';
}
.icon-icn-sport-blitzball:before {
	content: '\e98c';
}
.icon-icn-sport-catchball:before {
	content: '\e98d';
}
.icon-icn-sport-roller-derby:before {
	content: '\e98e';
}
.icon-rc-star-advanced:before {
	content: '\e98f';
}
.icon-rc-star-intermediate:before {
	content: '\e990';
}
.icon-rc-star-medal:before {
	content: '\e991';
}
.icon-rc-trophy-unknown:before {
	content: '\e992';
}
.icon-rc-age:before {
	content: '\e993';
}
.icon-rc-sign:before {
	content: '\e994';
}
.icon-rc-circle-check:before {
	content: '\e995';
}
.icon-rc-circle-close:before {
	content: '\e996';
}
.icon-rc-circle-empty:before {
	content: '\e997';
}
.icon-rc-post-empty:before {
	content: '\e998';
}
.icon-rc-wand:before {
	content: '\e999';
}
.icon-rc-circle-exclamation:before {
	content: '\e99a';
}
.icon-rc-email-sending:before {
	content: '\e99b';
}
.icon-rc-circle-question:before {
	content: '\e99c';
}
.icon-rc-playoff-finals-icon:before {
	content: '\e99d';
}
.icon-rc-playoff-round-icon:before {
	content: '\e99e';
}
.icon-rc-player-shirt:before {
	content: '\e99f';
}
.icon-rc-success-shield:before {
	content: '\e9a0';
}
.icon-rc-download:before {
	content: '\e9a1';
}
.icon-rc-questionnaire:before {
	content: '\e9a2';
}
.icon-rc-reports:before {
	content: '\e9a3';
}
.icon-rc-logout:before {
	content: '\e9a4';
}
.icon-rc-cash:before {
	content: '\e9a5';
}
.icon-rc-online:before {
	content: '\e9a6';
}
.icon-rc-victory:before {
	content: '\e9a7';
}
.icon-rc-video:before {
	content: '\e9a8';
}
.icon-rc-account:before {
	content: '\e9a9';
}
.icon-rc-card:before {
	content: '\e9aa';
}
.icon-rc-notifications:before {
	content: '\e9ab';
}
.icon-rc-thumbs-down:before {
	content: '\e9ac';
}
.icon-rc-bell:before {
	content: '\e9ad';
}
.icon-rc-chat:before {
	content: '\e9ae';
}
.icon-rc-filter-v2:before {
	content: '\e9af';
}
.icon-rc-speaker-share:before {
	content: '\e9b0';
}
.icon-rc-search:before {
	content: '\e9b1';
}
.icon-rc-help:before {
	content: '\e9b2';
}
.icon-rc-logout-v2:before {
	content: '\e9b3';
}
.icon-rc-settings-cogs:before {
	content: '\e9b4';
}
.icon-rc-actions-menu:before {
	content: '\e9b5';
}
.icon-rc-shirt-board:before {
	content: '\e9b6';
}
.icon-rc-cup-hand:before {
	content: '\e9b7';
}
.icon-rc-beer:before {
	content: '\e9b8';
}
